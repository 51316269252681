import React from "react"
import Button from "../components/Button"
import AniLink from "gatsby-plugin-transition-link/AniLink";

// const goToForm = () => {
// 	console.log("hola mundo")
// }

let comments = [{
    title: 'Confiamos en Pentcloud, porque tienen un gran equipo en todo momento',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    author: {
        name: 'Ramiro Carpio',
        job:'Owner of TopBoost Community'
    }
},{
    title: 'Hemos trabajado con Pentcloud durante 3 años',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    author: {
        name: 'Margarita Jimenez',
        job:'CEO of Frontliner Technology'
    }
},{
    title: 'Pentcloud provee un gran servicio y estoy satisfecho con los resultados',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    author: {
        name: 'Trinidad Rosado',
        job:'Digital Marketing'
    }
}]

export default function about(props) {
    return (
        <>
            <div className="container mx-auto p-12 lg:px-0 grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-12">
                <div className="col-span-1 flex justify-center items-center">
                    <h1 className="text-5xl text-left font-bold text-gray">
                        Testimonios
                    </h1>
                </div>
                <div className="col-span-1 flex flex-row justify-start items-center">
                    <span className="text-gray-400 text-base leading-loose">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    </span>
                </div>
            </div>
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 lg:mt-32 mt-12 justify-center rounded-lg bg-blue-900 shadow-xl p-12">
                <div className="col-span-1 flex flex-col">
                    <div className="flex flew-row mb-12">
                        {[1,2,3,4,5].map((a, i) =>
                            <img src="/images/YellowStar.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full mr-4" key={i}/>
                        )}
                    </div>
                    <h2 className="text-4xl text-left font-bold text-white mb-12">
                        Encontrar una empresa creativa no es fácil en estos días.
                    </h2>
                    <span className="text-white text-base leading-loose">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    </span>
                </div>
                <div className="col-span-1 flex flew-row justify-end items-end">
                        <div>
                            <h3 className="text-white text-base leading-loose font-bold">
                                Roberto Gonzalez
                            </h3>
                            <span className="text-white text-sm">
                                CEO
                            </span>
                        </div>
                        <img src="/images/YellowStar.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 border-2 border-white rounded-full ml-4"/>
                </div>
            </div>
            <div className="container mx-auto p-12 lg:px-0 grid grid-cols-1 md:grid-cols-3 gap-12 mt-12 lg:mt-32 mt-12">
                {comments.map((comment, i) =>
                    <div className="col-span-1 flex flex-col ">
                        <div className="rounded-xl bg-white shadow-xl p-12 mb-12 flex-grow">
                            <h2 className="text-2xl font-bold text-blue-900 mb-12">
                                {comment.title}
                            </h2>
                            <span className="text-gray-400 text-base leading-loose">
                                {comment.description}
                            </span>
                        </div>
                        <div className="flex flew-row items-center">
                            <img src="/images/YellowStar.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 border-2 border-gray-200 rounded-full mr-4"/>
                            <div>
                                <h3 className="text-gray text-base leading-loose font-bold">
                                    {comment.author.name}
                                </h3>
                                <span className="text-gray text-sm">
                                    {comment.author.job}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="container mx-auto flex flex-col lg:mt-40 mt-12 items-center justify-center rounded-lg bg-blue-900 shadow-xl p-12">
                <h2 className="text-white font-extrabold text-4xl mb-12">
                    ¿Tienes un proyecto que quieres volver realidad?
                </h2>
                <AniLink paintDrip hex="#FFFFFF" to="/#mainForm" activeClassName="font-bold text-blue">
                    <Button classes="px-8 py-4 bg-white font-bold text-green-900 rounded-lg">
                        Contáctanos ahora
                    </Button>
                </AniLink>
            </div>
        </>
    )
}